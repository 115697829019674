/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-28 18:45:41
 * @Module: 编辑主播
 */
  <template>
  <el-card style="padding-right:160px"
           class="anchor-form-style">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="80px"
             :size="'mini'"
             :label-position="'top'">
      <el-row :gutter="90">
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="探探号："
                        prop="tick_account"
                        :error="errorMessage?errorMessage.tick_account:''">
            <el-input v-model="form.tick_account"
                      placeholder="请输入探探号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="探探ID："
                        prop="tick_id"
                        :error="errorMessage?errorMessage.tick_id:''">
            <el-input v-model="form.tick_id"
                      placeholder="请输入探探ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="经纪人："
                        prop="broker_id"
                        :error="errorMessage?errorMessage.broker_id:''">
            <brokerInviter @change="id=>form.broker_id = id"
                           placeholder="请选择经纪人"
                           :defaultvalue="form.broker_id"
                           class="width100"></brokerInviter>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="公会："
                        prop="mcn_id"
                        :error="errorMessage?errorMessage.mcn_id:''">
            <mcn class="width100"
                 :filterable="false"
                 @change="id=>form.mcn_id=id"
                 placeholder="请选择公会"
                 :defaultvalue="form.mcn_id" />
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="真实姓名："
                        prop="name"
                        :error="errorMessage?errorMessage.name:''">
            <el-input v-model="form.name"
                      placeholder="请输入真实姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="手机号："
                        prop="name"
                        :error="errorMessage?errorMessage.phone:''">
            <el-input v-model="form.phone"
                      type="tel"
                      placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主播昵称"
                        prop="tick_name"
                        :error="errorMessage?errorMessage.tick_name:''">
            <el-input v-model="form.tick_name"
                      placeholder="请输入真主播昵称">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="分成比："
                        prop="separate_ratio"
                        :error="errorMessage?errorMessage.separate_ratio:''">
            <pushMoney class="width100"
                       :filterable="false"
                       @change="id=>form.separate_ratio=id"
                       placeholder="请选择分成比"
                       :defaultvalue="form.separate_ratio" />
          </el-form-item>
        </el-col>

        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="邀约状态："
                        prop="invite_state"
                        :error="errorMessage?errorMessage.invite_state:''">
            <inviteState @change="id=>form.invite_state = id"
                         :filterable="false"
                         placeholder="请选择邀约状态"
                         class="width100"
                         :defaultvalue="form.invite_state"></inviteState>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注："
                        prop="description"
                        :error="errorMessage?errorMessage.description:''">
            <el-input v-model="form.description"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 5}"
                      placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="primary"
                   @click="submit"
                   :loading="reqLoading">确定</el-button>
      </el-row>
    </el-form>
  </el-card>
</template>
 <script>
import brokerInviter from "components/formComponent/brokerInviter";
import mcn from "components/formComponent/mcn";
import pushMoney from "components/formComponent/pushMoney";
import inviteState from "components/formComponent/inviteState";
export default {
  data () {
    return {
      form: {
        tick_account: "", //探探号
        tick_id: "", //探探id
        broker_id: "", //经纪人
        mcn_id: "", //公会
        name: "", // 真实姓名
        phone: "", //电话
        separate_ratio: "", //分成比
        invite_state: "", //邀约状态
        description: "", //备注
        tick_name: ""  //主播昵称
      },
      rules: null,
      errorMessage: null,
      reqLoading: false,
    };
  },
  created () {
    const field = [
      "anchor_id",
      "tick_account",
      "tick_id",
      "broker_id",
      "mcn_id",
      "name",
      "phone",
      "separate_ratio",
      "invite_state",
      "description",
    ];
    field.forEach((res) => {
      this.form[res] = this.$route.params[res];
    });
    this.form.tick_name = this.$route.params.nick_name
  },
  components: {
    brokerInviter,
    mcn,
    pushMoney,
    inviteState,
  },
  mounted () {
    if (this.role(["administrator"]))
      this.rules = {
        tick_account: [
          { required: true, message: "请输入探探号", trigger: "blur" },
        ],
        broker_id: [
          { required: true, message: "请选择经纪人", trigger: "blur" },
        ],
        mcn_id: [{ required: true, message: "请选择公会", trigger: "blur" }],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        separate_ratio: [
          { required: true, message: "请选择分成比", trigger: "blur" },
        ],
        invite_state: [
          { required: true, message: "请选择邀约状态", trigger: "blur" },
        ],
        tick_name: [
          { required: true, message: "请输入主播昵称", trigger: "blur" },
        ],
      };
  },
  methods: {
    submit () {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._reqAnchorUpdate();
          console.log("验证通过");
        } else {
          return false;
        }
      });
    },
    // 请求更新主播
    async _reqAnchorUpdate () {
      this.reqLoading = true;
      const { form } = this;
      const { status_code, message } = await this.$api.anchorUpdate({
        ...form,
      });
      if (status_code === 200) {
        this.$router.go(-1);
        this.$message({
          message,
          type: "success",
        });
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
  },
};
</script>
 <style lang="less" scoped>
.width100 {
  width: 100%;
}
.anchor-form-style /deep/ .el-input__inner {
  height: 40px;
}
.anchor-form-style /deep/ .el-form-item {
  margin-bottom: 40px;
}
</style>