/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-31 17:40:39
 * @Module: 选择审批状态
 */
 <template>
  <el-select
    v-model="id"
    :placeholder="placeholder"
    :clearable="clearable"
    @change="change"
    @clear="clear"
    :filterable="filterable"
    :loading="loading"
  >
    <el-option v-for="item in list" :key="item.value" :value="item.value" :label="item.title"></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "请选择",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    defaultvalue: {
      type: Number,
      defaule: null,
    },
  },
  data() {
    return {
      id: null,
      list: [],
      loading: true,
    };
  },
  components: {},
  mounted() {
    this.id = this.defaultvalue;
    this._initState();
  },
  methods: {
    async _initState() {
      const { status_code, message } = await this.$api.inviteStateListOptions();
      if (status_code === 200) {
        this.list = message;
      }
      this.loading = false;
    },
    change(val) {
      this.$emit("change", val);
    },
    clear() {
      this.$emit("change", null);
    },
  },
};
</script>
<style>
</style>